import React from "react";
import cx from "classnames";
import { useSiteContext, useToggleMenu } from "src/context/siteContext";

import * as styles from "./hamburger.module.css";

interface HamburgerProps {
	className?: string;
}

export const Hamburger = ({ className }: HamburgerProps) => {
	const { menuIsOpen } = useSiteContext();
	const toggleMenu = useToggleMenu();
	return (
		<button
			onClick={toggleMenu}
			className={cx(
				styles.hamburger,
				styles.hamburgerSqueeze,
				menuIsOpen && styles.isActive,
				className
			)}
			type="button"
			aria-label="Toggle Main Menu"
			aria-haspopup="true"
			aria-expanded={menuIsOpen}
		>
			<span className={styles.hamburgerBox}>
				<span className={styles.hamburgerInner} />
			</span>
		</button>
	);
};
