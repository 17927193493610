import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { getInternalLinkPath } from "src/utils/getInternalLinkPath";

interface LinkProps {
	className?: string;
	activeClassName?: string;
	type: "internal" | "external";
	link?: {
		linkType?: string;
		linkCopy?: string;
		internalLink?: {
			link: {};
			section?: {
				slug: {
					current: string;
				};
			};
		};
		externalLink?: {
			link: string;
			targetBlank: boolean;
		};
	};
	to?: string;
	href?: string;
	ariaLabel?: string;
	onClick?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	onMouseOver?: () => void;
	children?: any;
}
export const Link = ({
	className,
	activeClassName = "active",
	type,
	link,
	to,
	href,
	ariaLabel,
	onClick,
	onMouseEnter,
	onMouseLeave,
	onMouseOver,
	children,
}: LinkProps) => {
	switch (type) {
		case "internal":
			const target = to
				? to
				: link &&
				  link.internalLink &&
				  getInternalLinkPath(link.internalLink.link);

			return (
				<GatsbyLink
					type={type}
					className={className}
					activeClassName={activeClassName}
					onClick={onClick}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onMouseOver={onMouseOver}
					to={target || ""}
					aria-label={ariaLabel}
				>
					{children}
				</GatsbyLink>
			);
		case "external":
			return (
				<a
					className={className}
					onClick={onClick}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onMouseOver={onMouseOver}
					href={
						href ? href : link && link.externalLink && link.externalLink.link
					}
					target={
						link && link.externalLink && !link.externalLink.targetBlank
							? ""
							: "_blank"
					}
					rel="noopener noreferer"
					aria-label={ariaLabel}
				>
					{children}
				</a>
			);

		default:
			return null;
	}
};
