import React from "react";
import cx from "classnames";
// @ts-ignore
import SanityBlockContent from "@sanity/block-content-to-react";
import { Serializer } from "src/utils/serializer";
import { SplitLetterSerializer } from "src/utils/splitLetterSerializer";

interface BlockContentProps {
	className?: string;
	blocks: any[];
	splitLetters?: boolean;
}

export const BlockContent = ({
	blocks,
	className,
	splitLetters = false,
}: BlockContentProps) => {
	if (!blocks) {
		// console.error("Missing blocks");
		return null;
	}

	const serializer = splitLetters ? SplitLetterSerializer : Serializer;

	return (
		<div className={cx("block-content", className)}>
			<SanityBlockContent blocks={blocks} serializers={serializer} />
		</div>
	);
};
