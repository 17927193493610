import React from "react";
import cx from "classnames";
import { Link } from "src/components/link";
import { BlockContent } from "src/components/block-content";
import { Signup } from "src/components/signup";
import { Image } from "src/components/image";
import { useFooterData } from "src/hooks/useFooterData";

import * as styles from "./footer.module.css";

export const Footer = ({ location }: { location: any }) => {
	const {
		headline,
		cities,
		subscribePrompt,
		menuSections,
		certification,
		graphics,
	} = useFooterData();

	const pageGraphic = graphics.find(
		(graphic: {
			page: { content: { main: { slug: { current: string } } } };
		}) => {
			if (location.pathname === "/") {
				return graphic.page.content.main.slug.current === "homepage";
			} else {
				return (
					graphic.page.content.main.slug.current ===
					location.pathname.replace("/", "")
				);
			}
		}
	);

	return (
		<footer className={cx(styles.footer, "double-border-top")}>
			<div className={styles.inner}>
				<div className={styles.row}>
					<div>
						<div className={styles.headlineGraphic}>
							<BlockContent
								className={cx("small-headline")}
								blocks={headline}
							/>
							{pageGraphic?.png?.asset && (
								<Image
									className={cx("hide-small", styles.graphic)}
									alt={pageGraphic.png.alt}
									imageId={pageGraphic.png.asset._ref}
								/>
							)}
						</div>

						<div className={styles.cities}>
							{pageGraphic?.png?.asset && (
								<Image
									className={cx("hide-min-medium", styles.graphic)}
									alt={pageGraphic.png.alt}
									imageId={pageGraphic.png.asset._ref}
								/>
							)}
							{cities.map((city: string, index: number) => (
								<span
									key={index}
									className={cx("rotate-colors small-body")}
									style={{ animationDelay: `-${index / 2}s` }}
								>
									{index != 0 ? ", " : ""}
									{city}
								</span>
							))}
						</div>
					</div>
					<Image
						className={cx("hide-max-medium")}
						src="/AnimatedLogo.gif"
						height={92}
						alt="12 Logo Animation"
					/>
					<Image
						className={cx("hide-min-large", styles.animatedLogoMobile)}
						src="/AnimatedLogoMobile.gif"
						width={280}
						alt="12 Logo Animation"
					/>
				</div>
				<div className={styles.row}>
					<Signup prompt={subscribePrompt} />
					<div className={styles.menuSections}>
						{menuSections &&
							menuSections.map((section: any) => {
								return (
									<div className={styles.menuSection} key={section._key}>
										{section.title && (
											<h4 className={cx("label", styles.menuSectionTitle)}>
												{section.title}
											</h4>
										)}
										<ul>
											{section.items &&
												section.items.map((item: any) => {
													return (
														<li className={styles.menuItem} key={item._key}>
															<Link
																className={styles.menuLink}
																type={item.linkType}
																link={item}
															>
																<span className="underline-on-hover small-body">
																	{item.linkCopy}
																</span>
															</Link>
														</li>
													);
												})}
										</ul>
									</div>
								);
							})}
					</div>
					{certification.png.asset && (
						<Link
							className={styles.bCorpLink}
							href={certification.url}
							type="external"
						>
							<Image
								alt={certification.png.alt}
								src={certification.png.asset.url}
								width={42}
							/>
						</Link>
					)}
				</div>
			</div>
			<div className={cx("caption", styles.copyright)}>
				Copyright twelveNYC {new Date().getFullYear()}
			</div>
		</footer>
	);
};
