import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import cx from "classnames";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import { useIdleTimer } from "react-idle-timer";
import { AnimatePresence } from "framer-motion";
import { Screensaver } from "src/components/screensaver";
import { Header } from "src/components/header";
import { Footer } from "src/components/footer";
import { useMediaQuery } from "@material-ui/core/";
import useVH from "react-viewport-height";

import "src/styles/main.css";

interface LayoutProps {
	children: any;
	location: {
		action: string;
		pathname: string;
	};
}

const Layout = ({ children, location }: LayoutProps) => {
	const isMouse = useMediaQuery("(pointer: fine)");
	const [isClient, setClient] = useState(false);
	const [enableFocusOutlines, setEnableFocusOutlines] = useState(false);
	const [isIdle, setIsIdle] = useState(false);

	useVH();

	useEffect(() => {
		setClient(true);
		window && window.addEventListener("keydown", handleKeydown);
		return () => window && window.removeEventListener("keydown", handleKeydown);
	}, []);

	const key = isClient ? `client` : `server`;

	const handleKeydown = (e: KeyboardEvent) => {
		// Detect a keyboard user from a tab key press
		const isTabEvent = e.keyCode === 9;

		if (isTabEvent) {
			setEnableFocusOutlines(true);
		}
	};

	const handleOnIdle = (event) => {
		// console.log("user is idle", event);
		// console.log("last active", getLastActiveTime());
		setIsIdle(true);
	};

	const handleOnActive = (event) => {
		// console.log("user is active", event);
		// console.log("time remaining", getRemainingTime());
		setIsIdle(false);
	};

	const { getRemainingTime, getLastActiveTime } = useIdleTimer({
		timeout: 1000 * 60,
		onIdle: handleOnIdle,
		onActive: handleOnActive,
		debounce: 200,
	});

	return (
		<>
			<Helmet
				title="twelveNYC"
				htmlAttributes={{
					class: cx(enableFocusOutlines ? "" : "no-outline-on-focus"),
				}}
			/>
			<SkipNavLink />
			{isMouse && (
				<AnimatePresence>{isIdle && <Screensaver />}</AnimatePresence>
			)}
			<Header location={location} />
			<div>
				{/* <Header />
				<SearchDrawer /> */}
				<div className="page-container" key={key}>
					<SkipNavContent />
					<main className="page-content">{children}</main>
					<Footer location={location} />
				</div>
			</div>
		</>
	);
};

export default Layout;
