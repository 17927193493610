// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-capabilities-tsx": () => import("./../../../src/pages/capabilities.tsx" /* webpackChunkName: "component---src-pages-capabilities-tsx" */),
  "component---src-pages-clients-tsx": () => import("./../../../src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-emea-tsx": () => import("./../../../src/pages/emea.tsx" /* webpackChunkName: "component---src-pages-emea-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-style-guide-tsx": () => import("./../../../src/pages/style-guide.tsx" /* webpackChunkName: "component---src-pages-style-guide-tsx" */),
  "component---src-pages-sustainability-tsx": () => import("./../../../src/pages/sustainability.tsx" /* webpackChunkName: "component---src-pages-sustainability-tsx" */),
  "component---src-pages-who-we-are-tsx": () => import("./../../../src/pages/who-we-are.tsx" /* webpackChunkName: "component---src-pages-who-we-are-tsx" */),
  "component---src-pages-work-tagged-tsx": () => import("./../../../src/pages/work/tagged.tsx" /* webpackChunkName: "component---src-pages-work-tagged-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-templates-text-page-tsx": () => import("./../../../src/templates/textPage.tsx" /* webpackChunkName: "component---src-templates-text-page-tsx" */)
}

