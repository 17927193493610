export const getInternalLinkPath = (reference: any) => {
	if (reference?.content?.main) {
		if (reference.content.main.slug?.current === "homepage") {
			return `/`;
		} else if (reference._type === "project") {
			return `/work/${reference.content.main.slug.current}`;
		} else {
			return `/${reference.content.main.slug.current}/`;
		}
	} else {
		return `/`;
	}
};
