import React, { useState, useEffect, useContext } from "react";

interface State {
	headerIsVisible: boolean;
	menuIsOpen: boolean;
	filterIsOpen: boolean;
	searchIsOpen: boolean;
	page: undefined;
	activeTags: [
		{ title?: string; name?: string; slug: { current: string } } | undefined
	];
}

const initialState = {
	headerIsVisible: true,
	menuIsOpen: false,
	filterIsOpen: false,
	searchIsOpen: false,
	page: undefined,
	activeTags: [],
};

const SiteContext = React.createContext({
	state: initialState,
	setState: () => null,
});

const SiteContextProvider = ({ children }: { children: any }) => {
	const [state, setState] = useState(initialState);
	const [initState, setInitState] = useState(false);

	useEffect(() => {
		if (initState === false) {
			setInitState(true);
		}
	}, [state, setState, initState]);

	return (
		<SiteContext.Provider
			value={{
				state,
				setState,
			}}
		>
			{children}
		</SiteContext.Provider>
	);
};

function useSiteContext() {
	const { state } = useContext(SiteContext);
	return state;
}

function useSetPage() {
	const {
		setState,
	}: {
		setState: any;
	} = useContext(SiteContext);
	async function setPage(page: string) {
		setState((prevState: State) => {
			return { ...prevState, page };
		});
	}
	return setPage;
}

function useSetHeaderVisibility() {
	const {
		state: { headerIsVisible },
		setState,
	}: {
		state: {
			headerIsVisible: boolean;
		};
		setState: any;
	} = useContext(SiteContext);

	function setHeaderVisibility(visible: boolean) {
		setState((prevState: State) => {
			return { ...prevState, headerIsVisible: visible };
		});
	}

	return setHeaderVisibility;
}

function useToggleMenu() {
	const {
		state: { menuIsOpen },
		setState,
	}: {
		state: {
			menuIsOpen: boolean;
		};
		setState: any;
	} = useContext(SiteContext);

	function toggleMenu() {
		setState((prevState: State) => {
			return { ...prevState, menuIsOpen: !menuIsOpen };
		});
	}

	return toggleMenu;
}

function useToggleFilter() {
	const {
		state: { filterIsOpen, searchIsOpen },
		setState,
	}: {
		state: {
			filterIsOpen: boolean;
			searchIsOpen: boolean;
		};
		setState: any;
	} = useContext(SiteContext);

	function toggleFilter() {
		setState((prevState: State) => {
			return {
				...prevState,
				filterIsOpen: !filterIsOpen,
				searchIsOpen: !filterIsOpen ? false : searchIsOpen,
			};
		});
	}

	return toggleFilter;
}

function useToggleSearch() {
	const {
		state: { searchIsOpen, filterIsOpen },
		setState,
	}: {
		state: {
			searchIsOpen: boolean;
			filterIsOpen: boolean;
		};
		setState: any;
	} = useContext(SiteContext);

	function toggleFilter() {
		setState((prevState: State) => {
			return {
				...prevState,
				searchIsOpen: !searchIsOpen,
				filterIsOpen: !searchIsOpen ? false : filterIsOpen,
			};
		});
	}

	return toggleFilter;
}

function useSetActiveTags() {
	const {
		setState,
	}: {
		setState: any;
	} = useContext(SiteContext);

	function setActiveTags(
		activeTags:
			| [
					{
						_id?: string;
						title?: string;
						name?: string;
						slug: { current: string };
					}
			  ]
			| []
	) {
		setState((prevState: State) => {
			return {
				...prevState,
				activeTags,
			};
		});
	}

	return setActiveTags;
}

export {
	SiteContextProvider,
	useSiteContext,
	useSetPage,
	useSetHeaderVisibility,
	useToggleMenu,
	useToggleFilter,
	useToggleSearch,
	useSetActiveTags,
};
