import { useStaticQuery, graphql } from "gatsby";
export const useHeaderData = () => {
	const { sanityHeader } = useStaticQuery(
		graphql`
			query HeaderQuery {
				sanityHeader {
					menu: _rawMenu(resolveReferences: { maxDepth: 6 })
					mobileMenu: _rawMobileMenu(resolveReferences: { maxDepth: 6 })
				}
			}
		`
	);

	return sanityHeader;
};
