import React from "react";
import cx from "classnames";
import { Modal, Fade } from "@material-ui/core";
import { Link } from "src/components/link";
import { Backdrop } from "src/components/backdrop";
import { useSiteContext, useToggleMenu } from "src/context/siteContext";

import * as styles from "./mobile-menu.module.css";

interface MobileMenuProps {
	data: [
		{
			_key: string;
			title?: string;
			items: [
				{
					_key: string;
					linkCopy: string;
					linkType: string;
					internalLink?: any;
					externalLink?: string;
				}
			];
		}
	];
}

export const MobileMenu = ({ data }: MobileMenuProps) => {
	const { menuIsOpen } = useSiteContext();
	const toggleMenu = useToggleMenu();

	const handleMenuItemClick = () => {
		menuIsOpen && toggleMenu();
	};

	return (
		<Modal
			open={menuIsOpen}
			onClose={() => toggleMenu()}
			closeAfterTransition={true}
			BackdropComponent={Backdrop}
			BackdropProps={{ active: menuIsOpen, toggle: toggleMenu }}
			style={{ zIndex: `1100` }}
			aria-modal="true"
			aria-labelledby="mobileMenuLabel"
		>
			<Fade
				in={menuIsOpen}
				timeout={{
					enter: 400,
					exit: 250,
				}}
			>
				<div className={cx(styles.drawer)} role="presentation">
					<div className={styles.drawerInner}>
						<h2 id="mobileMenuLabel" className="visually-hidden">
							Menu
						</h2>
						<nav>
							{data.map((menuSection, sectionIndex) => (
								<div className={styles.menuSection} key={menuSection._key}>
									{menuSection.title && (
										<span
											className={cx(
												"label",
												styles.menuSectionTitle,
												menuIsOpen && styles.reveal
											)}
										>
											{menuSection.title}
										</span>
									)}
									<ul className={styles.menuList} role="menu">
										{menuSection.items.map((menuItem: any, index: number) => {
											return (
												<li
													className={cx(
														styles.menuItem,
														menuIsOpen && styles.reveal
													)}
													key={index}
													style={
														{
															"--delay": `${index * 40 + sectionIndex * 160}ms`,
														} as React.CSSProperties
													}
												>
													<Link
														type={menuItem.linkType}
														link={menuItem}
														onClick={handleMenuItemClick}
													>
														<span className={cx("title")}>
															{menuItem.linkCopy}
														</span>
													</Link>
												</li>
											);
										})}
									</ul>
								</div>
							))}
						</nav>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};
