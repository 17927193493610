import React, { useState, useEffect } from "react";
import cx from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
// @ts-ignore
import { getGatsbyImageData } from "gatsby-source-sanity";
import { pure } from "recompose";
import { useInView } from "react-intersection-observer";

const sanityConfig = {
	projectId: process.env.GATSBY_SANITY_PROJECT_ID,
	dataset: process.env.GATSBY_SANITY_DATASET,
};

export const Image = React.memo(
	({
		asset,
		imageId,
		className,
		gatsbyImageClassName,
		gatsbyImageImgClassName,
		width,
		height,
		alt = "",
		src,
		style,
		imgStyle,
		objectFit,
		objectPosition,
		loading = "lazy",
	}: {
		asset?: any;
		imageId?: string;
		width?: number;
		height?: number;
		alt: string;
		className?: string;
		gatsbyImageClassName?: string;
		gatsbyImageImgClassName?: string;
		src?: string;
		style?: object;
		imgStyle?: object;
		objectFit?:
			| "none"
			| "contain"
			| "fill"
			| "cover"
			| "scale-down"
			| undefined;
		objectPosition?: string;
		loading?: "eager" | "lazy";
	}) => {
		const [loaded, setLoaded] = useState(false);
		let imageData;

		const { ref, inView } = useInView({
			rootMargin: "2000px 0px",
			triggerOnce: true,
		});

		if ((asset || imageId) && !/gif/.test(asset?._id || imageId)) {
			imageData = getGatsbyImageData(
				asset || imageId,
				{ maxWidth: width || 2400 },
				sanityConfig
			);
		}

		return (
			<figure className={cx(className)}>
				{imageData && !/svg/.test(asset?._id || imageId) ? (
					<GatsbyImage
						image={{
							...imageData,
							layout: "fullWidth",
						}}
						alt={alt}
						className={gatsbyImageClassName}
						imgClassName={gatsbyImageImgClassName}
						style={style}
						imgStyle={imgStyle}
						objectFit={objectFit ? objectFit : "cover"}
						objectPosition={objectPosition}
						loading={loading}
					/>
				) : (
					<img
						ref={ref}
						alt={alt}
						src={inView ? (src ? src : imageData && imageData.src) : ""}
						loading="lazy"
						className={cx("fade-in-on-load", {
							"is-loaded": loaded,
						})}
						onLoad={() => {
							setLoaded(true);
						}}
						style={style}
						width={width || "auto"}
						height={height || "auto"}
					/>
				)}
			</figure>
		);
	}
);

export const PureImage = pure(Image);
// Image.whyDidYouRender = true;
