import React from "react";
// @ts-ignore
import SanityBlockContent from "@sanity/block-content-to-react";
import { Link } from "src/components/link";
import { getInternalLinkPath } from "./getInternalLinkPath";

const ListSerializer = ({
	type,
	children,
}: {
	type: string;
	children: any;
}) => {
	return type === "bullet" ? <ul>{children}</ul> : <ol>{children}</ol>;
};

const ListItemSerializer = ({ children }: { children: any }) => {
	return <li>{children}</li>;
};

export const Serializer = {
	list: ListSerializer,
	listItem: ListItemSerializer,
	types: {
		block: (props: any) => {
			switch (props.node.style) {
				case "subheading":
					return <span className="label block my1">{props.children}</span>;
					break;
				case "headline":
					return <span className="title block">{props.children}</span>;
					break;
				default:
					return SanityBlockContent.defaultSerializers.types.block(props);
			}
		},
	},
	marks: {
		em: (props: { children: any }) => (
			<em>
				<span>{props.children}</span>
			</em>
		),
		uppercase: (props: { children: any }) => (
			<span className="upper">{props.children}</span>
		),
		link: ({ mark, children }: { mark: any; children: any }) => {
			const { blank, href } = mark;
			return blank ? (
				<a href={href} target="_blank" rel="noopener">
					{children}
				</a>
			) : (
				<a href={href}>{children}</a>
			);
		},
		internalLink: ({ mark, children }: { mark: any; children: any }) => {
			const path =
				mark?.reference?.link && getInternalLinkPath(mark.reference.link);

			if (!path) {
				return null;
			}
			return (
				<Link type="internal" to={path}>
					{children}
				</Link>
			);
		},
	},
};
