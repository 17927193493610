import React from "react";
import cx from "classnames";

import * as styles from "./backdrop.module.css";

export const Backdrop = ({
  active,
  toggle
}: {
  active: boolean;
  toggle: () => void;
}) => {
  return (
    <div
      aria-hidden="true"
      className={cx(styles.backdrop, active ? styles.active : "")}
      onClick={toggle}
    />
  );
};
