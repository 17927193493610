import React from "react";

import * as styles from "./shadow-border.module.css";

export const ShadowBorder = ({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) => {
	return (
		<div className={className}>
			<div className={styles.shadowDark}>
				<div className={styles.shadowLight}>{children}</div>
			</div>
		</div>
	);
};
