import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import { useMatch } from "@reach/router";
import { navigate } from "gatsby";
import { motion } from "framer-motion";
import ScrollLock from "react-scrolllock";
import { ThemeProvider } from "@material-ui/core/styles";
import { Collapse } from "@material-ui/core";
import { ProjectSearch } from "src/components/work/project-search";

import { useFilterData } from "src/hooks/useFilterData";
import { useOnClickOutsideOf } from "src/hooks/useOnClickOutsideOf";

import {
	useSiteContext,
	useToggleFilter,
	useToggleSearch,
	useSetActiveTags,
} from "src/context/siteContext";

import { ReactComponent as Search } from "src/images/svg/Search.svg";
import { ReactComponent as X } from "src/images/svg/X.svg";
import { ReactComponent as CaretDown } from "src/images/svg/CaretDown.svg";

import { theme } from "src/utils/createMuiTheme";
import * as styles from "./project-filter.module.css";
// import TaggedPage from "src/pages/work/tagged";

interface Tag {
	title?: string;
	name?: string;
	slug: {
		current: string;
	};
}

const FilterListItem = ({
	tag,
	handleTagClick,
}: {
	tag: Tag;
	handleTagClick: (tag: Tag) => void;
}) => {
	return (
		<li className={styles.filterListItem}>
			<button onClick={() => handleTagClick(tag)} className={cx("reset body")}>
				<span className="underline-on-hover">{tag.title}</span>
			</button>
		</li>
	);
};

interface ProjectFilterProps {
	location: any;
}

export const ProjectFilter = ({ location }: ProjectFilterProps) => {
	const { filters, tags } = useFilterData();
	const { filterIsOpen, searchIsOpen, activeTags } = useSiteContext();
	const toggleFilter = useToggleFilter();
	const toggleSearch = useToggleSearch();

	const filterRef = useRef() as React.MutableRefObject<HTMLDivElement>;
	const filterUIRef = useRef() as React.MutableRefObject<HTMLDivElement>;

	const handleClickOutside = () => {
		if (filterIsOpen) {
			toggleFilter();
		}
	};

	useOnClickOutsideOf(filterRef, handleClickOutside);

	const handleTagClick = (tag: Tag) => {
		navigate(`/work/tagged/${tag.slug.current}`, {
			replace: true,
		});

		toggleFilter();
	};

	useEffect(() => {
		if (filterIsOpen) {
			filterUIRef.current.scrollTo(0, 0);
		}
	}, [filterIsOpen]);

	return (
		<motion.div
			initial={{ opacity: 0, y: `-100%` }}
			animate={{ opacity: 1, y: `0` }}
			exit={{ opacity: 0, y: `-100%` }}
			className={styles.filterSearch}
			ref={filterRef}
		>
			<div className={styles.filterSearchHeader}>
				<button
					className={cx("reset f aic", styles.filterToggle)}
					onClick={toggleFilter}
				>
					<span className={cx("label", filterIsOpen && "underline")}>
						Filters
					</span>
					<CaretDown
						className={cx("ml05", styles.caret, filterIsOpen && styles.flip)}
					/>
				</button>
				<button className={cx("reset f aic")} onClick={toggleSearch}>
					<span className={cx("label", searchIsOpen && "underline")}>
						Client Search
					</span>
					<span className={styles.searchIconContainer}>
						{searchIsOpen ? (
							<X className="ml05" />
						) : (
							<Search className="ml05" />
						)}
					</span>
				</button>
			</div>

			<ThemeProvider theme={theme}>
				<Collapse in={filterIsOpen}>
					<div className={styles.drawer} role="presentation">
						<div className={cx(styles.filter)}>
							<div className={styles.filterUI} ref={filterUIRef}>
								<div className={styles.filterSection}>
									<h4 className={cx("label mb1")}>Type of work</h4>
									<ul className={styles.filterList}>
										{filters.typeOfWorkFilterTags.tags &&
											filters.typeOfWorkFilterTags.tags.map((tag: Tag) => {
												return (
													<FilterListItem
														key={tag.slug.current}
														tag={tag}
														handleTagClick={handleTagClick}
													/>
												);
											})}
									</ul>
								</div>
								<div className={styles.filterSection}>
									<h4 className={cx("label mb1")}>Type of client</h4>
									<ul className={styles.filterList}>
										{filters.typeOfClientFilterTags.tags &&
											filters.typeOfClientFilterTags.tags.map((tag: Tag) => {
												return (
													<FilterListItem
														key={tag.slug.current}
														tag={tag}
														handleTagClick={handleTagClick}
													/>
												);
											})}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</Collapse>
				<Collapse in={searchIsOpen}>
					<ProjectSearch />
				</Collapse>
			</ThemeProvider>
			<ScrollLock isActive={searchIsOpen} />
		</motion.div>
	);
};
