import React, { useState, useRef, useEffect } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import cx from "classnames";
import { Index } from "lunr";
import { useSiteContext, useToggleSearch } from "src/context/siteContext";

import * as styles from "./project-search.module.css";

export const ProjectSearch = () => {
	const { searchIsOpen } = useSiteContext();
	const toggleSearch = useToggleSearch();

	const [query, setQuery] = useState("");
	const inputEl = useRef(null);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setQuery(e.target.value);
	};

	const data = useStaticQuery(graphql`
		query {
			LunrIndex
		}
	`);

	// LunrIndex is available via page query
	const { store } = data.LunrIndex;
	// lunr in action here
	const index = Index.load(data.LunrIndex.index);
	let results = [];
	if (query != "") {
		try {
			// search is a lunr method
			results = index.search(`${query}*`).map(({ ref }) => {
				// Map search results to an array of {slug, title, excerpt} objects
				return {
					slug: ref,
					...store[ref],
				};
			});
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		if (searchIsOpen && inputEl) {
			inputEl?.current.focus();
		}
	}, [searchIsOpen, inputEl]);
	return (
		<div className={cx(styles.searchDrawer)}>
			<div>
				<input
					className={cx("label", styles.input)}
					ref={inputEl}
					id="projectSearchInput"
					type="text"
					value={query}
					placeholder="Search by client name"
					onChange={handleChange}
					aria-label="Search by client name"
				/>

				{results?.length > 0 && (
					<div className={styles.results}>
						{results.map((project) => (
							<Link
								key={project.slug}
								to={`/work/${project.slug}`}
								className={styles.projectLink}
								onClick={toggleSearch}
							>
								<span
									className={cx("body c-text-olive")}
									dangerouslySetInnerHTML={{
										__html: project.title.replace(
											new RegExp(query, "gi"),
											(match: string) => `<span class="c-black">${match}</span>`
										),
									}}
								/>
							</Link>
						))}
					</div>
				)}
			</div>
		</div>
	);
};
