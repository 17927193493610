import { useStaticQuery, graphql } from "gatsby";
export const useFooterData = () => {
	const { sanityFooter } = useStaticQuery(
		graphql`
			query FooterQuery {
				sanityFooter {
					headline: _rawHeadline(resolveReferences: { maxDepth: 10 })
					menuSections: _rawMenuSections(resolveReferences: { maxDepth: 10 })
					cities
					subscribePrompt
					certification: _rawCertification(resolveReferences: { maxDepth: 6 })
					graphics: _rawGraphics(resolveReferences: { maxDepth: 2 })
				}
			}
		`
	);

	return sanityFooter;
};
