import React from "react";
// @ts-ignore
import SanityBlockContent from "@sanity/block-content-to-react";
import { Link } from "src/components/link";
import { getInternalLinkPath } from "./getInternalLinkPath";

const ListSerializer = ({
	type,
	children,
}: {
	type: string;
	children: any;
}) => {
	return type === "bullet" ? <ul>{children}</ul> : <ol>{children}</ol>;
};

const ListItemSerializer = ({ children }: { children: any }) => {
	return <li>{children}</li>;
};

export const SplitLetterSerializer = {
	list: ListSerializer,
	listItem: ListItemSerializer,

	types: {
		block: (props: any) => {
			// console.log(SanityBlockContent.defaultSerializers.types.block(props));
			let characterCount = 0;
			const modifiedBlockContent = SanityBlockContent.defaultSerializers.types
				.block(props)
				.props.children.map((Block: React.ReactElement) => {
					let text;
					if (Block.props?.node?.children) {
						text = Block?.props?.node?.children.join(" ");
					} else if (typeof Block == "string") {
						text = Block;
					}

					let modifiedText;
					if (text) {
						modifiedText = [...text].map((letter) => {
							characterCount++;
							return (
								<span
									key={`${characterCount}-${letter}`}
									className="letter"
									style={{ "--index": characterCount } as React.CSSProperties}
								>
									{letter}
								</span>
							);
						});
					}
					if (Block.props?.node?.children) {
						Block.props.node.children = [modifiedText];
					} else if (typeof Block == "string") {
						Block = <>{modifiedText}</>;
					}
					return Block;
				});

			return modifiedBlockContent;
		},
	},
	marks: {
		uppercase: (props: { children: any }) => (
			<span className="upper">{props.children}</span>
		),
		link: ({ mark, children }: { mark: any; children: any }) => {
			const { blank, href } = mark;
			return blank ? (
				<a href={href} target="_blank" rel="noopener">
					{children}
				</a>
			) : (
				<a href={href}>{children}</a>
			);
		},
		internalLink: ({ mark, children }: { mark: any; children: any }) => {
			const path =
				mark?.reference?.link && getInternalLinkPath(mark.reference.link);

			if (!path) {
				return null;
			}
			return (
				<Link type="internalLink" to={path}>
					{children}
				</Link>
			);
		},
	},
};
