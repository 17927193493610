import React, { useState, useRef } from "react";
import cx from "classnames";
import { useMatch } from "@reach/router";
import { AnimatePresence } from "framer-motion";
import { Link } from "src/components/link";
import { ProjectFilter } from "src/components/work/project-filter";
import { Hamburger } from "src/components/hamburger";
import { MobileMenu } from "src/components/mobile-menu";
import { useHeaderData } from "src/hooks/useHeaderData";
import { useDocumentScrollThrottled } from "src/hooks/useDocumentScrollThrottled";
import {
	useSiteContext,
	useSetHeaderVisibility,
	useToggleMenu,
} from "src/context/siteContext";

import { ReactComponent as Logo } from "src/images/svg/TwelveHeaderLogo.svg";

import * as styles from "./header.module.css";

export const Header = ({ location }: { location: any }) => {
	const { headerIsVisible, menuIsOpen } = useSiteContext();
	const setHeaderVisibility = useSetHeaderVisibility();
	const toggleMenu = useToggleMenu();

	const { menu, mobileMenu } = useHeaderData();

	const handleLogoClick = () => {
		if (menuIsOpen) {
			toggleMenu();
		}
	};

	// DIY headroom functionality
	const MINIMUM_SCROLL = 40;
	const TIMEOUT_DELAY = 0;

	useDocumentScrollThrottled(
		(callbackData: { previousScrollTop: number; currentScrollTop: number }) => {
			const { previousScrollTop, currentScrollTop } = callbackData;
			const isScrolledDown = previousScrollTop < currentScrollTop;
			const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

			setTimeout(() => {
				if (isScrolledDown && isMinimumScrolled) {
					// hide header when scrolling down
					setHeaderVisibility(false);
				} else {
					// reveal when scrolling up
					setHeaderVisibility(true);
				}
			}, TIMEOUT_DELAY);
		}
	);

	const matchWorkIndex = useMatch("/work");
	const matchTaggedWorkIndex = useMatch("/work/tagged/*");

	const showProjectFilter = matchWorkIndex || matchTaggedWorkIndex;

	return (
		<>
			<header
				className={cx(
					styles.header,
					!headerIsVisible && styles.hidden,
					menuIsOpen && styles.menuIsOpen
				)}
			>
				<div className={styles.inner}>
					<Link
						type="internal"
						to="/"
						className={styles.logoLink}
						onClick={handleLogoClick}
					>
						<span className="visually-hidden">twelveNYC</span>
						<Logo />
					</Link>
					<nav>
						<ul className={styles.menuList}>
							{menu.items.map((menuItem: any, index: number) => {
								return (
									<li className={styles.menuItem} key={index}>
										<Link
											type={menuItem.linkType}
											link={menuItem}
											className={cx(
												styles.menuLink,
												index === 0 ? "rotate-colors" : ""
											)}
										>
											<span className={cx("label underline-on-hover")}>
												{menuItem.linkCopy}
											</span>
										</Link>
									</li>
								);
							})}
						</ul>
					</nav>
					<Hamburger className={cx("hide-min-large", styles.hamburger)} />
				</div>
				<AnimatePresence>
					{showProjectFilter && !menuIsOpen && (
						<ProjectFilter location={location} />
					)}
				</AnimatePresence>
				<MobileMenu data={mobileMenu} />
			</header>
		</>
	);
};
