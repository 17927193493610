import { useStaticQuery, graphql } from "gatsby";
export const useFilterData = () => {
	const { filters, tags } = useStaticQuery(
		graphql`
			query FilterQuery {
				filters: sanityWork {
					content {
						main {
							filters: _rawFilters(resolveReferences: { maxDepth: 10 })
						}
					}
				}
				tags: allSanityProjectTag {
					edges {
						node {
							_id
							slug {
								current
							}
							title
						}
					}
				}
			}
		`
	);

	return {
		filters: filters.content.main.filters,
		tags: tags.edges,
	};
};
