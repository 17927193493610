import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useMailChimp } from "react-use-mailchimp-signup";
import { ShadowBorder } from "src/components/shadow-border";

import * as styles from "./signup.module.css";

interface SignupProps {
	prompt: string;
	className?: string;
}

export const Signup = ({ prompt, className }: SignupProps) => {
	const { error, loading, status, subscribe, message } = useMailChimp({
		action: `https://twelveNYC.us3.list-manage.com/subscribe/post?u=a96f3b0fdac5fa5a45909eae7&amp;id=f5eb7466a7`,
	});

	const [inputs, setInputs] = useState({ email: "" });
	const [placeholder, setPlaceholder] = useState("Enter Your Email");
	const [errorStyling, setErrorStyling] = useState(false);

	const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
		event.persist();

		setErrorStyling(false);

		const { name, value } = event.target as HTMLInputElement;

		setInputs((inputs) => ({
			...inputs,
			[name]: value,
		}));
	};

	const handleSubmit = (event: React.SyntheticEvent) => {
		if (event) {
			event.preventDefault();
		}
		if (inputs) {
			subscribe(inputs);
		}
	};

	useEffect(() => {
		if (error) {
			setInputs({ email: "" });
			setPlaceholder("Try Again!");
			setErrorStyling(true);
		} else if (status === "duplicate") {
			setInputs({ email: "" });
			setPlaceholder("Already Subscribed!");
			setErrorStyling(true);
		}
	}, [error, status]);

	return (
		<form onSubmit={handleSubmit} className={styles.form}>
			<label className={cx("label", styles.label)} htmlFor="mailchimpEmail">
				{prompt}
			</label>
			{status === "success" ? (
				<p className={cx("small-body", styles.success)}>
					Thank you for signing up
				</p>
			) : (
				<div className={styles.inputContainer}>
					<input
						value={inputs.email}
						type="email"
						name="email"
						id="mailchimpEmail"
						onChange={handleInputChange}
						placeholder={placeholder}
						className={cx(
							"small-body",
							styles.input,
							errorStyling && styles.error
						)}
					/>
					<button type="submit" className={styles.submit}>
						<ShadowBorder>
							<div className={styles.submitInner}>
								<span className={cx("label")}>Submit</span>
							</div>
						</ShadowBorder>
					</button>
				</div>
			)}
		</form>
	);
};
