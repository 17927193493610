import React from "react";
import cx from "classnames";
import { motion } from "framer-motion";

import { ReactComponent as Logo } from "src/images/svg/ScreensaverLogo.svg";

import * as styles from "./screensaver.module.css";

export const Screensaver = () => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className={cx(styles.screensaver)}
		>
			<div className={styles.x}>
				<Logo className={cx(styles.y, styles.logo)} />
			</div>
		</motion.div>
	);
};
