import React from "react";
import { SiteContextProvider } from "src/context/siteContext";
import "intersection-observer";

export const wrapRootElement = ({ element }) => (
	<SiteContextProvider>{element}</SiteContextProvider>
);

export const onClientEntry = () => {
	if (process.env.NODE_ENV !== "production") {
		const whyDidYouRender = require("@welldone-software/why-did-you-render");
		whyDidYouRender(React);
	}
};
